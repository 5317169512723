import React from 'react'
import { graphql } from 'gatsby'
import BlogCloud from '../components/BlogCloudBut2ColWithAbsLeft/component';
import BackLookBlock from '../components/BackLookBlock/component';
import Helmet from 'react-helmet'
import styled from 'react-emotion'
import Header from '../components/Header'
import Content from '../components/Content'
import Preview from '../components/Preview'
import Footer from '../components/Footer'

const Root = styled.div`
display: flex;
flex-direction: column;
height: 100%;
`
const getParams = (search = '') => {
  return search.replace('?', '').split('&').reduce((params, keyValue) => {
    const [key, value = ''] = keyValue.split('=')
    if (key && value) {
      params[key] = value.match(/^\d+$/) ? +value : value
    }
    return params
  }, {})
}

export default function Blog({ data, location, ...rest }) {
  const isPost =
          location.pathname !== '/' && !location.pathname.match(/^\/blog\/?$/)
  const { edges: posts } = data.allMarkdownRemark
  const { start = 0, end = 10 } = getParams(location.search)

  return (<BlogCloud posts={posts}><BackLookBlock /></BlogCloud>)
}

export const pageQuery = graphql`
query BlogQuery {
  site {
    siteMetadata {
      title
      author
    }
  }
  allMarkdownRemark(
    sort: { order: DESC, fields: [frontmatter___date] }
    filter: { frontmatter: { draft: { ne: true } } }
  ) {
    edges {
      node {
          ...Post
      }
    }
  }
}
`

