import React, { Component } from 'react'
import styled from 'react-emotion'
import './component.css'
import backView from './backView3.gif';
// import cloudTop from './top.png';
// import selfPortrait from './smallerSelfie.png';
// import PostPreview from './postPreview.js';
// import SocialIcons from '../SocialIcons.js';

// return (
//     <React.Fragment>
//     {posts
//      .filter(post => post.node.frontmatter.title.length > 0)
//      .slice(start, end)
//      .map(({ node: post }) => {
//        return (
//            <div key={post.title}>
//            <Preview
//          excerpt={post.frontmatter.excerpt || post.excerpt}
//          date={post.frontmatter.date}
//          title={post.frontmatter.title}
//          to={post.slug}
//            />
//            </div>
//        )
//      })}
// </React.Fragment>
// )

const MeContainer = styled.div`
  display: flex;
  background-color: white;
`

const MeImg = styled.div`
  width: 300px;
  margin: 100px auto;
`

export default function({children, posts}) {
    // const { children } = this.props
    return (
      <MeContainer className="meContainer">
        <MeImg>
          <img src={backView} />
        </MeImg>
      </MeContainer>
    )
}